import React from "react";
import DropDownField from "@components/Forms/DropDownField";
import FormTextField from "@components/Forms/FormTextField";
import FormNumberField from "@components/Forms/FormNumberField";
import FormDateField from "@components/Forms/FormDateField";
import { formatDate } from "@utils/helperFunctions";
import MultipleLineField from "@components/Forms/MulitpleLineField.js";

function ProjectVariables({
  arr,
  account_slug,
  project_id,
  setState,
  setVariableChanged,
}) {
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;
  const location = window.location.href;
  const lastWordInLocation = location.substr(location.lastIndexOf("/") + 1);
  const components = [];
  const today = new Date();

  if (arr.length === 0) {
    return null;
  }

  arr.map((pv, index) => {
    let variableType = "";
    if (pv["variable-type"]) {
      variableType = pv["variable-type"];
    }
    if (pv["variable_type"]) {
      variableType = pv["variable_type"];
    }

    const renderKey = () => {
      let selectOptions = pv["select_options"];
      for (let i = 0; i < selectOptions.length; i++) {
        if (pv.value === selectOptions[i].value) {
          return selectOptions[i].key;
        }
      }
    };
    if (pv["select_options"].length !== 0) {
      components.push(
        <div className="pvDiv" key={pv.name}>
          <DropDownField
            required={pv.required}
            label={pv.required === true ? pv.label + " *" : pv.label}
            key={index}
            value={renderKey()}
            onChange={(e) => {
              let selected = e.target.options.selectedIndex;
              setState(
                arr.map((variable) =>
                  variable.name === pv.name
                    ? {
                        ...variable,
                        value: e.target.options[selected].accessKey,
                      }
                    : variable
                )
              );
              if (setVariableChanged !== null) setVariableChanged(true);
            }}
            options={pv["select_options"].map((option) => {
              return (
                <option accessKey={option.value} key={option.value}>
                  {option.key}
                </option>
              );
            })}
          />
        </div>
      );
    }
    if (variableType === "text" && pv["select_options"].length === 0 && 
       ((pv.maximum && pv.maximum <= 256) || !pv.maximum) ) {
      components.push(
        <div className="pvDiv" key={pv.name}>
          <FormTextField
            required={pv.required}
            key={index}
            value={pv.value}
            label={pv.required === true ? pv.label + " *" : pv.label}
            onChange={(e) => {
              setState(
                arr.map((variable) =>
                  variable.name === pv.name
                    ? { ...variable, value: e.target.value }
                    : variable
                )
              );
              if (setVariableChanged !== null) setVariableChanged(true);
            }}
          />
        </div>
      );
    }
    if (
      variableType === "text" &&
      pv["select_options"].length === 0 &&
      pv.maximum &&
      pv.maximum > 256
    ) {
      components.push(
        <div className="pvDiv" key={pv.name}>
          <MultipleLineField
            required={pv.required}
            rows={3}
            key={index}
            value={pv.value}
            label={pv.required === true ? pv.label + " *" : pv.label}
            onChange={(e) => {
              setState(
                arr.map((variable) =>
                  variable.name === pv.name
                    ? { ...variable, value: e.target.value }
                    : variable
                )
              );
              if (setVariableChanged !== null) setVariableChanged(true);
            }}
          />
        </div>
      );
    }
    if (variableType === "number" && pv["select_options"].length === 0) {
      components.push(
        <div className="pvDiv" key={pv.name}>
          <FormNumberField
            required={pv.required}
            key={index}
            value={pv.value}
            label={pv.required === true ? pv.label + " *" : pv.label}
            min={pv.minimum}
            max={pv.maximum}
            onChange={(e) => {
              setState(
                arr.map((variable) =>
                  variable.name === pv.name
                    ? { ...variable, value: e.target.value }
                    : variable
                )
              );
              if (setVariableChanged !== null) setVariableChanged(true);
            }}
          />
        </div>
      );
    }
    if (variableType === "date" && pv["select_options"].length === 0 && pv.name !== 'msa_date') {
      components.push(
        <div className="pvDiv" key={pv.name}>
          <FormDateField
            required={pv.required}
            key={index}
            defaultValue={
              pv.required === true && lastWordInLocation.includes("new")
                ? formatDate(today)
                : pv.value
            }
            label={pv.required === true ? pv.label + " *" : pv.label}
            onChange={(e) => {
              setState(
                arr.map((variable) =>
                  variable.name === pv.name
                    ? { ...variable, value: e.target.value }
                    : variable
                )
              );
              if (setVariableChanged !== null) setVariableChanged(true);
            }}
          />
        </div>
      );
    }
  });
  return <>{[components]}</>;
}

export default ProjectVariables;
