import { General, Products, TeamsTags } from "../common";
import { Button } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router";
import { useEffect, useState, useCallback } from "react";
import { Service } from "../common/types";
import {
  useGetServiceById,
  useListResourcesForAccount,
  useListTeamsForAccount,
  useListLOEs,
} from "../common/api";
import { Tab, Tabs, Form } from "react-bootstrap";
import {
  useUpdateServiceMutation,
  V1SubserviceResource,
  useCreateSubserviceMutation,
  useUpdateSubserviceMutation,
  useWhoAmIQuery,
} from "@generated";
import { useSelector } from "react-redux";
import { RootState } from "@reducers/rootReducer";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import { handleAlert } from "@utils/helperFunctions";
import { formatServiceData } from "../Show/common/helpers";
import { Option } from "../Show/types";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import VersionHistory from "@components/VersionHistory";
import ServiceLanguage from "../ServiceLanguage";
import UnsavedChangesModal from "@components/UnsavedChangesModal";
import ScopeStackModal from "@components/ScopeStackModal/ScopeStackModal";

const Edit = ({ permission, auditLogPermission, account }): JSX.Element => {
  const { accountSlug } = useSelector((state: RootState) => state.slug);
  const { data: user } = useWhoAmIQuery();
  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from;
  const url = window.location.href;
  const managedService: boolean = url.includes("managed_services");
  const id = url.split("/").slice(-2, -1);
  const { serviceData, isLoading, isFetching, refetch } = useGetServiceById(id);
  const { teams } = useListTeamsForAccount();
  const [changes, setChanges] = useState<boolean>(false);
  const [service, setService] = useState<Service>({
    id: "",
    name: "",
    phase: "",
    category: "",
    resource: "",
    sku: "",
    hours: "",
    tags: [],
    teams: [],
    frequency: "",
    languages: {},
    "service-description": "",
    subservices: [],
  });
  const [prevService, setPrevService] = useState<Service>({
    id: "",
    name: "",
    phase: "",
    category: "",
    resource: "",
    sku: "",
    hours: "",
    tags: [],
    teams: [],
    frequency: "",
    languages: {},
    "service-description": "",
    subservices: [],
  });
  const { resources } = useListResourcesForAccount();
  const [resourceOptions, setResourceOptions] = useState<Option[]>([]);
  const [updateService] = useUpdateServiceMutation();
  const [createSubservice] = useCreateSubserviceMutation();
  const [updateSubservice] = useUpdateSubserviceMutation();

  const [showFailAlert, setShowFailAlert] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [updating, setUpdating] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>("1");
  const [pendingTab, setPendingTab] = useState<string | null>(null);
  const [showLoseChangesConfirmation, setShowLoseChangesConfirmation] =
    useState<boolean>(false);
  const [showTeamsConfirmation, setShowTeamsConfirmation] =
    useState<boolean>(false);
  const [teamsOverride, setTeamsOverride] = useState<boolean>(false);
  const [resetData, setResetData] = useState<boolean>(false);

  useEffect(() => {
    const alertKey = "createdAlertShown";

    // Check if the user came from the new route and if the alert hasn't been shown yet
    if (from?.includes("new") && !localStorage.getItem(alertKey)) {
      setSuccessMessage("Service created");
      setShowSuccessAlert(true);
      localStorage.setItem(alertKey, "true");
    }
  }, [from]);

  useEffect(() => {
    if (
      !isLoading &&
      !isFetching &&
      serviceData?.id &&
      (serviceData.id !== service.id ||
        service.subservices.filter((s) => !s.id.includes("new")).length !==
          serviceData?.subservices?.length ||
        resetData)
    ) {
      const data = {
        id: serviceData.id,
        name: serviceData?.name || "",
        phase: serviceData?.phase || "",
        category: serviceData?.category || "",
        resource: serviceData?.resource || "",
        sku: serviceData?.sku || "",
        hours: serviceData?.hours || "",
        tags: serviceData?.tags || [],
        teams: serviceData?.teams || [],
        frequency: serviceData?.frequency || "",
        languages: serviceData?.languages,
        "service-description": serviceData?.["service-description"] || "",
        subservices: serviceData.subservices || [],
        hasAdvancedPricing: serviceData.hasAdvancedPricing || false,
      };

      setService(data);
      setPrevService(data);
      if (resetData) {
        setResetData(false);
      }
    }
  }, [isLoading, isFetching]);

  useEffect(() => {
    //check for changes from initial state to enable/disable "Save" button
    if (service?.id && prevService?.id) {
      //make sure "settingsOpen" property on subservices is ignored and they are sorted by position when comparing for changes
      const serviceClone = {
        ...service,
        subservices: service.subservices
          ?.map(({ settingsOpen, ...rest }) => rest)
          .sort((a, b) => a.position - b.position),
      };
      if (JSON.stringify(serviceClone) !== JSON.stringify(prevService)) {
        setChanges(true);
      } else {
        setChanges(false);
      }
    }
  }, [service]);

  useEffect(() => {
    if (resources.length !== resourceOptions.length) {
      setResourceOptions(resources);
    }
  }, [resources]);

  useEffect(() => {
    if (teamsOverride) {
      setShowTeamsConfirmation(false);
      onSave();
    }
  }, [teamsOverride]);

  const handleFailResponse = (msg) => {
    setUpdating(false);
    setErrorMessage(msg || "Something went wrong.");
    setShowFailAlert(true);
  };

  const onSave = async () => {
    if (
      !serviceData?.id ||
      !account?.attributes?.["account-id"] ||
      !user?.data?.id
    ) {
      handleFailResponse("");
      return;
    }

    //Check if user is NOT on any assigned teams
    if (activeTab === "4" && service.teams.length && !teamsOverride) {
      const userIsOnASelectedTeam = service.teams.some((selectedTeam) => {
        const team = teams.find((team) => team.value == selectedTeam.value);
        return team?.users?.includes(String(user?.data?.id));
      });

      if (!userIsOnASelectedTeam) {
        if (showLoseChangesConfirmation) setShowLoseChangesConfirmation(false);
        setShowTeamsConfirmation(true);
        return;
      }
    }
    setUpdating(true);
    const data = formatServiceData(
      service,
      account?.attributes?.["account-id"],
      managedService
    );

    if (managedService) delete data.relationships.phase;

    try {
      const res = await updateService({
        slug: accountSlug,
        id: data.id as number,
        body: { data },
      }).unwrap();

      const id = res?.data?.id;
      if (id) {
        //POST/PATCH subservices
        for (const subservice of service.subservices) {
          let data: V1SubserviceResource = {
            type: "subservices",
            attributes: {
              name: subservice.name,
              "suggested-hours": subservice?.hours
                ? Number(subservice.hours)
                : undefined,
              quantity: subservice.quantity,
              position: subservice.position,
              "service-description": subservice["service-description"],
              languages: subservice.languages,
            },
            relationships: {
              resource: {
                data: {
                  type: "resources",
                  id: subservice?.resource
                    ? Number(subservice.resource)
                    : Number(service.resource),
                },
              },
              service: {
                data: {
                  type: "services",
                  id,
                },
              },
            },
          };

          if (!subservice.id.includes("new") && subservice.isChanged) {
            data.id = Number(subservice.id);
            await updateSubservice({
              slug: accountSlug,
              id: Number(subservice.id),
              body: { data },
            });
          }
          if (subservice.id.includes("new")) {
            await createSubservice({ slug: accountSlug, body: { data } });
          }
        }

        if (showLoseChangesConfirmation) setShowLoseChangesConfirmation(false);
        setSuccessMessage("Service updated");
        setShowSuccessAlert(true);
        setUpdating(false);
        setChanges(false);
        if (teamsOverride) {
          setTimeout(() => {
            navigate(`/admin/${managedService ? "managed_services" : "tasks"}`);
          }, 2000);
        } else {
          refetch();
        }
      } else {
        handleFailResponse("");
      }
    } catch (error) {
      handleFailResponse("");
    }
  };

  const handleTabSelect = useCallback(
    (key: string | null) => {
      if (key && changes) {
        setPendingTab(key); // Set the pending tab if there are unsaved changes
        setShowLoseChangesConfirmation(true); // Show the confirmation modal
      } else if (key) {
        setActiveTab(key); // Directly set the active tab if no changes
      }
    },
    [changes]
  );

  const confirmRevertChanges = () => {
    if (pendingTab) {
      setActiveTab(pendingTab); // Set the active tab to the pending tab
      setShowLoseChangesConfirmation(false);
      setPendingTab(null);
      setChanges(false);
      refetch();
      setService({ ...prevService });
      setResetData(true); //trigger reset
    } else {
      navigate(`/admin/${managedService ? "managed_services" : "tasks"}`);
    }
  };

  const loading = isLoading || updating;

  return (
    <div className="profServicesV2 manageService">
      {handleAlert(
        showFailAlert,
        errorMessage,
        setShowFailAlert,
        "warning",
        ToastAlert
      )}
      {handleAlert(
        showSuccessAlert,
        successMessage,
        setShowSuccessAlert,
        "success",
        ToastAlert
      )}
      <div className="headerContainer">
        <div className="actionBtns">
          <Button
            className="cancelButton"
            onClick={(e) => {
              if (localStorage.getItem("createdAlertShown")) {
                localStorage.removeItem("createdAlertShown");
              }
              if (changes) {
                setShowLoseChangesConfirmation(true);
              } else {
                navigate(
                  `/admin/${managedService ? "managed_services" : "tasks"}`
                );
              }
            }}
          >
            {!changes ? "Back" : "Cancel"}
          </Button>
          <h2>{`Edit ${serviceData?.name || ""}`}</h2>
        </div>

        <div className="actionBtns">
          {permission === "manage" && (
            <Button
              className={`button ${!changes ? "disabled" : ""}`}
              onClick={() => onSave()}
              disabled={!changes}
            >
              Save
            </Button>
          )}
        </div>
      </div>
      <div className="tabs">
        <Tabs
          defaultActiveKey={1}
          activeKey={activeTab}
          id="uncontrolled-tab"
          className="mb-3  manageService"
          onSelect={(key) => {
            if (activeTab === "2") {
              const classList = document.getElementById(
                `multiCollapse${serviceData?.id}`
              )?.classList;
              if (!classList?.contains("show")) {
                classList?.add("show");
              }
              refetch();
            }
            handleTabSelect(key);
          }}
        >
          <Tab
            eventKey={1}
            title="General"
            tabClassName="profServicesTab"
            className="profServicesTabContent generalInfo"
          >
            {activeTab !== "1" || loading || !service.id ? (
              <SmallSpinner />
            ) : (
              <General
                service={service}
                setService={setService}
                isManagedService={managedService}
                permission={permission}
                editing={true}
                resourceOptions={resourceOptions}
                refetch={refetch}
                setResetData={setResetData}
                changes={changes}
              />
            )}
          </Tab>
          <Tab
            eventKey={2}
            title="Service Language"
            tabClassName="profServicesTab"
            className="profServicesTabContent serviceLanguage"
          >
            {activeTab !== "2" || loading ? (
              <SmallSpinner />
            ) : (
              <ServiceLanguage
                servicePassed={service}
                setServicePassed={setService}
              />
            )}
          </Tab>
          <Tab
            eventKey={3}
            title="Products"
            tabClassName="profServicesTab"
            className="profServicesTabContent serviceProducts"
          >
            {activeTab !== "3" || loading ? (
              <SmallSpinner />
            ) : (
              <Products service={serviceData} refetch={refetch} />
            )}
          </Tab>

          <Tab
            eventKey={4}
            title="Assign Teams & Tags"
            tabClassName="profServicesTab"
            className="profServicesTabContent teamsTags"
          >
            {activeTab !== "4" || loading ? (
              <SmallSpinner />
            ) : (
              <TeamsTags
                service={service}
                setService={setService}
                teams={teams}
                permission={permission}
              />
            )}
          </Tab>
          {auditLogPermission === "none" ? null : (
            <Tab
              eventKey={5}
              title="Version History"
              tabClassName="profServicesTab"
              className="profServicesTabContent serviceVersionHistory"
            >
              {activeTab !== "5" || loading ? (
                <SmallSpinner />
              ) : (
                <div style={{ marginTop: "20px" }}>
                  <VersionHistory
                    filter={{
                      "context-type": "Task",
                      "context-id": serviceData?.id.toString(),
                    }}
                    permission={auditLogPermission === "manage"}
                    account={account}
                  />
                </div>
              )}
            </Tab>
          )}
        </Tabs>
        <UnsavedChangesModal
          show={showLoseChangesConfirmation}
          handleCancel={() => {
            setShowLoseChangesConfirmation(false);
            setPendingTab(null);
          }}
          handleDontSave={confirmRevertChanges}
          handleSave={onSave}
        />
        <ScopeStackModal
          show={showTeamsConfirmation}
          modalTitle="Confirm Teams Assignment"
          modalBody={
            <div style={{ marginBottom: "-30px" }}>
              <p>
                You are not a member of any of the selected teams. You will no
                longer be able to manage this service going forward.
              </p>
              <p>
                <strong>Are you sure you want to continue?</strong>
              </p>
            </div>
          }
          button1Text="Cancel"
          button2Text="Confirm"
          handleButton1Click={() => setShowTeamsConfirmation(false)}
          handleButton2Click={() => setTeamsOverride(true)}
          handleClose={() => setShowTeamsConfirmation(false)}
        />
      </div>
    </div>
  );
};

export default Edit;
