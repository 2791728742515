import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import NewProjectDetails from "./NewProjectDetails/NewProjectDetails";

function CreateProjectMspa({ account_id, account_slug, save_to_cloud_storage_flag }) {
  return (
    <main>
      <BrowserRouter>
        <Routes>
          <Route
            exact
            path={"/projects/new"}
            render={() => (
              <NewProjectDetails
                account_id={account_id}
                account_slug={account_slug}
                save_to_cloud_storage_flag={save_to_cloud_storage_flag}
              />
            )}
          />
        </Routes>
      </BrowserRouter>
    </main>
  );
}

export default CreateProjectMspa;
