import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import "./styles/SowHeader.css";
import { V1AccountResource, V1ProjectResource } from "@generated";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGear } from "@fortawesome/pro-light-svg-icons";
import API from "../../utils/API/API";
import useOauth from "../../utils/customHooks/useOauth";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import {
  formatUnprocessibleResponse,
  handleAlert,
} from "../../utils/helperFunctions";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import CurrencyFormat from "react-currency-format";
import ContextMenu from "@components/ContextMenu/ContextMenu";
import {
  getHeaderStatus,
  getMargin,
  getProfit,
  getSecondaryButtonByStatus,
} from "./utils/sowHeaderHelpers";
import ConfirmationModal from "@components/Modals/ConfirmationModal";
import ScopeStackModal from "@components/ScopeStackModal/ScopeStackModal";
import { Select2V2 } from "@components/FormsV2";
import { OptionType } from "../../pages/Products/types";
import GenerateDocument from "@components/GenerateDocument/GenerateDocument";

function SowHeader({ project_id, account_slug, save_to_cloud_storage_flag }) {
  const [authorizationCode] = useOauth();
  const appHost = process.env.REACT_APP_DOORKEEPER_APP_URL;
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const [project, setProject] = useState<V1ProjectResource | null>(null);
  const [projectLoading, setProjectLoading] = useState(true);
  const [showFailAlert, setShowFailAlert] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>("");
  const [account, setAccount] = useState<V1AccountResource | null>(null);
  const [currencyUnit, setCurrencyUnit] = useState<string>("");
  const [secondaryButton, setSecondaryButton] = useState({
    class: "btnSeawhite",
    text: "Request Approval",
  });
  const [statusToDisplay, setStatusToDisplay] = useState({
    class: "",
    text: "",
  });
  const [contextX, setContextX] = useState(0);
  const [contextY, setContextY] = useState(0);
  const [contextMenuOpen, setContextMenuOpen] = useState(false);
  const [projectStatus, setProjectStatus] = useState("building");
  const [needsRescope, setNeedsRescope] = useState(false);
  const [showConfirmArchiveModal, setShowConfirmArchiveModal] = useState(false);
  const [showConfirmDuplicateModal, setShowConfirmDuplicateModal] =
    useState(false);
  const [projectIsActive, setProjectIsActive] = useState(true);
  const [showAddTeammatesModal, setShowAddTeammatesModal] = useState(false);
  const [teammates, setTeammates] = useState<OptionType[] | []>([]);
  const [teammateOptions, setTeammateOptions] = useState([]);
  const [showGenerateDocumentModal, setShowGenerateDocumentModal] =
    useState(false);

  useEffect(() => {
    if (authorizationCode !== "") {
      API.Get(
        `${apiHost}/${account_slug}/v1/projects/${project_id}?include=account,business-unit,external-request,project-locations,payment-term,client,rate-table,psa-project`,
        authorizationCode
      ).then((res) => {
        const project = res?.data?.data;
        const projectStatus = project?.attributes?.status;
        setProjectStatus(projectStatus);
        setProjectIsActive(project?.attributes?.["active"]);
        let status = projectStatus;
        if (status.includes("approval")) {
          status = "approval";
        }
        const account = res?.data?.included.filter(
          (item) => item.type === "accounts"
        )[0];
        setAccount(account);
        const isPsa = account?.attributes?.["psa?"];
        if ((status === "approved" || status === "won") && isPsa) {
          status = status + "Psa";
        }
        const previouslyApproved =
          projectStatus === "won" ||
          projectStatus === "lost" ||
          projectStatus === "approved";
        let externalRequestStatus = null;
        if (project?.relationships?.["external-request"].data !== null) {
          externalRequestStatus = res?.data?.included.filter(
            (item) => item.type === "external-requests"
          )[0]?.status;
        }
        const needsRescope =
          previouslyApproved &&
          (externalRequestStatus === null ||
            externalRequestStatus !== "completed" ||
            externalRequestStatus !== "offered");
        setNeedsRescope(needsRescope);
        setProject(project);
        setProjectLoading(false);
        setSecondaryButton(getSecondaryButtonByStatus(status));
        setStatusToDisplay(getHeaderStatus(projectStatus));
        setCurrencyUnit(account?.attributes?.["field-labels"]?.currency_unit);
      });

      // Get users to add to teammate dropdown
      API.Get(
        `${apiHost}/${account_slug}/v1/users?page[size]=200`,
        authorizationCode
      ).then((response) => {
        setTeammateOptions(
          response.data.data.map((teammate) => {
            return { value: teammate.id, label: teammate.attributes.name };
          })
        );
      });
    }
  }, [authorizationCode]);

  const requestApproval = () => {
    API.Put(
      project?.["links"]?.["request-approval"],
      project,
      authorizationCode
    )
      .then((res) => {
        if (res.status == 200) {
          setSuccessMessage("The Project has been sent for approval!");
          setShowSuccessAlert(true);
          window.location.reload();
        }
      })
      .catch((err) => {
        setErrorMessage(formatUnprocessibleResponse(err, "project"));
        setShowFailAlert(true);
      });
  };

  const reScope = () => {
    API.Put(project?.["links"]?.["rescope"], project, authorizationCode)
      .then((res) => {
        if (res.status == 200) {
          setSuccessMessage("The Project has been resubmitted for scoping!");
          setShowSuccessAlert(true);
          window.location.reload();
        }
      })
      .catch((err) => {
        setErrorMessage(formatUnprocessibleResponse(err, "project"));
        setShowFailAlert(true);
      });
  };

  const cancelApproval = () => {
    API.Put(project?.["links"]?.["cancel-approval"], project, authorizationCode)
      .then((res) => {
        if (res.status == 200) {
          setSuccessMessage("The Project approval has been canceled!");
          setShowSuccessAlert(true);
          window.location.reload();
        }
      })
      .catch((err) => {
        setErrorMessage(formatUnprocessibleResponse(err, "project"));
        setShowFailAlert(true);
      });
  };

  const pushToPsa = () => {
    let url = `/projects/${project_id}/psa_project`;
    if (project?.relationships?.["psa-project"]?.data) {
      url += "/edit";
    } else {
      url += "/new";
    }
    window.open(url, "_blank");
  };

  const archive = () => {
    setShowConfirmArchiveModal(false);
    setContextMenuOpen(false);
    let project: any = {
      data: {
        type: "projects",
        id: project_id,
      },
    };
    if (!projectIsActive) {
      project = {
        data: {
          ...project.data,
          attributes: {
            active: true,
          },
        },
      };
    }
    let promise = projectIsActive
      ? API.Delete(
          `${apiHost}/${account_slug}/v1/projects/${project_id}`,
          project,
          authorizationCode
        )
      : API.Patch(
          `${apiHost}/${account_slug}/v1/projects/${project_id}`,
          project,
          authorizationCode
        );
    promise
      .then((res) => {
        setSuccessMessage(
          `This project has been successfully ${
            projectIsActive ? "archived" : "un-archived"
          }!`
        );
        setShowSuccessAlert(true);
        if (projectIsActive) {
          window.location.replace(`/projects`);
        } else {
          window.location.reload();
        }
      })
      .catch((err) => {
        setErrorMessage(formatUnprocessibleResponse(err, "project"));
        setShowFailAlert(true);
      });
  };

  const markWon = () => {
    API.Put(project?.["links"]?.["deal-won"], project, authorizationCode)
      .then((res) => {
        if (res.status == 200) {
          setSuccessMessage("The Project has been marked as won!");
          setShowSuccessAlert(true);
          window.location.reload();
          setShowSuccessAlert(true);
        }
      })
      .catch((err) => {
        setErrorMessage(formatUnprocessibleResponse(err, "Project"));
        setShowFailAlert(true);
      });
  };

  const markLost = () => {
    API.Put(project?.["links"]?.["deal-lost"], project, authorizationCode)
      .then((res) => {
        if (res.status == 200) {
          setSuccessMessage("The Project has been marked as lost!");
          setShowSuccessAlert(true);
          window.location.reload();
        }
      })
      .catch((err) => {
        setErrorMessage(formatUnprocessibleResponse(err, "Project"));
        setShowFailAlert(true);
      });
  };

  const duplicateProject = () => {
    setShowConfirmDuplicateModal(false);
    setContextMenuOpen(false);
    let projectData = {
      data: {
        type: "projects",
        id: project_id,
      },
    };
    API.Post(project?.["links"]?.duplicate, projectData, authorizationCode)
      .then((response) => {
        if (response.status == 200) {
          setSuccessMessage("Project duplicated!");
          setShowSuccessAlert(true);
        }
      })
      .catch((err) => {
        setErrorMessage(formatUnprocessibleResponse(err, "project"));
        setShowFailAlert(true);
      });
  };

  const addTeammates = () => {
    setShowAddTeammatesModal(false);
    setContextMenuOpen(false);
    if (teammates.length === 0) return;
    let promises: any[] = [];
    teammates.forEach((teammate) => {
      const collaboratorData = {
        data: {
          type: "project-collaborators",
          attributes: {},
          relationships: {
            project: { data: { type: "projects", id: project_id } },
            user: { data: { type: "users", id: Number(teammate.value) } },
          },
        },
      };

      promises.push(
        API.Post(
          `${apiHost}/${account_slug}/v1/project-collaborators`,
          collaboratorData,
          authorizationCode
        )
      );
    });
    Promise.all(promises)
      .then((response) => {
        setSuccessMessage("Teammates added!");
        setShowSuccessAlert(true);
        window.location.reload();
      })
      .catch((err) => {
        setErrorMessage(formatUnprocessibleResponse(err, "teammate"));
        setShowFailAlert(true);
      });
  };

  const syncCrm = () => {
    API.Post(
      `${apiHost}/${account_slug}/v1/projects/${project_id}/sync-crm`,
      project,
      authorizationCode
    )
      .then((res) => {
        setSuccessMessage("CRM Synced!");
        setShowSuccessAlert(true);
        window.location.reload();
      })
      .catch((err) => {
        setErrorMessage(formatUnprocessibleResponse(err, "teammate"));
        setShowFailAlert(true);
      });
  };

  const closeContextMenu = () => {
    setContextMenuOpen(false);
    document.removeEventListener("click", closeContextMenu);
  };

  const getContextMenuItems = () => {
    let menuItems = [
      {
        newTab: false,
        onClick: () => {
          setShowConfirmDuplicateModal(true);
        },
        iconClass: "",
        style: { padding: "0 0.5em 0 0" },
        name: "Duplicate",
        hasBreakingLine: false,
      },
      {
        newTab: false,
        onClick: () => {
          window.open(`/projects/${project_id}/sow_blueprints/new`, "_blank");
        },
        iconClass: "",
        style: { padding: "0 0.5em 0 0" },
        name: "Create Blueprint",
        hasBreakingLine: false,
      },
      {
        newTab: false,
        onClick: () => {
          setShowConfirmArchiveModal(true);
        },
        iconClass: "",
        style: { padding: "0 0.5em 0 0" },
        name: "Archive",
        hasBreakingLine: false,
      },
      {
        newTab: false,
        onClick: () => {
          reScope();
        },
        iconClass: "",
        style: { padding: "0 0.5em 0 0" },
        name: "Re-Scope",
        hasBreakingLine: true,
      },
      {
        newTab: false,
        onClick: () => {
          markWon();
        },
        iconClass: "",
        style: { padding: "0 0.5em 0 0" },
        name: "Deal Won",
        hasBreakingLine: false,
      },
      {
        newTab: false,
        onClick: () => {
          markLost();
        },
        iconClass: "",
        style: { padding: "0 0.5em 0 0" },
        name: "Deal Lost",
        hasBreakingLine: true,
      },
      {
        newTab: false,
        onClick: () => {
          setShowAddTeammatesModal(true);
        },
        iconClass: "",
        style: { padding: "0 0.5em 0 0" },
        name: "Invite Teammates",
        hasBreakingLine: true,
      },
      {
        newTab: false,
        onClick: () => {
          syncCrm();
        },
        iconClass: "",
        style: { padding: "0 0.5em 0 0" },
        name: "Update CRM",
        hasBreakingLine: true,
      },
      {
        newTab: false,
        onClick: () => {
          window.open(
            `${appHost}/projects/${project_id}/merge_data_visualization`,
            "_blank"
          );
        },
        iconClass: "",
        style: { padding: "0 0.5em 0 0" },
        name: "View Merge Fields",
        hasBreakingLine: false,
      },
    ];
    if (!needsRescope) {
      menuItems = menuItems.filter((item) => item.name !== "Re-Scope");
    }
    if (projectStatus !== "approved" && projectStatus !== "won") {
      menuItems = menuItems.filter((item) => item.name !== "Create Blueprint");
      if (projectStatus !== "lost") {
        menuItems = menuItems.filter((item) => item.name !== "Duplicate");
      }
    }
    if (projectStatus !== "building") {
      menuItems = menuItems.filter((item) => item.name !== "Invite Teammates");
    }
    if (projectStatus === "lost") {
      menuItems = menuItems.filter((item) => item.name !== "Update CRM");
    }
    if (!projectIsActive) {
      menuItems = [
        {
          newTab: false,
          onClick: () => {
            setShowConfirmArchiveModal(true);
          },
          iconClass: "",
          style: { padding: "0 0.5em 0 0" },
          name: "Un-archive",
          hasBreakingLine: false,
        },
      ];
    }
    return menuItems;
  };

  return (
    <div className="sowHeader">
      {handleAlert(
        showFailAlert,
        errorMessage,
        setShowFailAlert,
        "warning",
        ToastAlert
      )}
      {handleAlert(
        showSuccessAlert,
        successMessage,
        setShowSuccessAlert,
        "success",
        ToastAlert
      )}
      {projectLoading ? (
        <SmallSpinner />
      ) : (
        <div className="headerContainer">
          {contextMenuOpen && (
            <ContextMenu
              menuItems={getContextMenuItems()}
              style={{
                zIndex: "100",
                left: contextX,
                top: contextY,
                backgroundColor: "lightgray",
                boxShadow: "0px 2px 3px 0px rgba(0, 0, 0, 0.15)",
              }}
            />
          )}
          <div style={{ marginLeft: "16px" }}>
            <div className="projectName">
              <span>
                {project?.attributes?.["project-name"]}&nbsp;
                <FontAwesomeIcon
                  icon={faGear}
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    let width = e.pageX;
                    setContextX(width);
                    let height = e.pageY;
                    setContextY(height);
                    setContextMenuOpen(!contextMenuOpen);
                    document.addEventListener("click", closeContextMenu);
                  }}
                />
              </span>
            </div>
            <div className="flexCenter">
              <div className={statusToDisplay.class}>
                {statusToDisplay.text}
              </div>
              <div className="projectData">
                <span>
                  <strong>Client: </strong>
                  {project?.attributes?.["client-name"]}
                </span>
                &nbsp;&nbsp;&nbsp; |&nbsp;&nbsp;&nbsp;
                <span>
                  <strong>Revenue: </strong>{" "}
                  <CurrencyFormat
                    displayType="text"
                    prefix={currencyUnit}
                    isNumericString
                    thousandSeparator
                    value={Number(
                      project?.attributes?.["total-revenue"] || 0
                    ).toFixed(2)}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                <span className="cost">
                  <strong>Cost: </strong>{" "}
                  <CurrencyFormat
                    displayType="text"
                    prefix={currencyUnit}
                    isNumericString
                    thousandSeparator
                    value={Number(
                      project?.attributes?.["contract-cost"] || 0
                    ).toFixed(2)}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                <span className="profit">
                  <strong>Profit: </strong>{" "}
                  <CurrencyFormat
                    displayType="text"
                    prefix={currencyUnit}
                    isNumericString
                    thousandSeparator
                    value={getProfit(project)}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;
                <span className="profit">
                  <strong>Margin: </strong> {getMargin(project)}%
                </span>
              </div>
            </div>
          </div>
          <div className="actionButtons projectActionButtons">
            {projectIsActive && secondaryButton.text !== "" && (
              <Button
                className={`${secondaryButton.class} marginRight10`}
                onClick={() => {
                  switch (secondaryButton.text) {
                    case "Request Approval":
                      requestApproval();
                      break;
                    case "Re-Scope":
                      reScope();
                      break;
                    case "Cancel Approval":
                      cancelApproval();
                      break;
                    case "Push To PSA":
                      pushToPsa();
                      break;
                    default:
                      break;
                  }
                }}
              >
                {secondaryButton.text}
              </Button>
            )}
            {projectIsActive && (
              <Button
                className="squareGreenButton"
                onClick={() => setShowGenerateDocumentModal(true)}
              >
                Generate Document
              </Button>
            )}
          </div>
        </div>
      )}
      <ConfirmationModal
        show={showConfirmArchiveModal}
        title={projectIsActive ? "Archive Project" : "Un-archive Project"}
        message={`Are you sure you want to ${
          projectIsActive ? "archive" : "un-archive"
        } this project?`}
        onConfirm={() => archive()}
        onCancel={() => setShowConfirmArchiveModal(false)}
      />
      <ConfirmationModal
        show={showConfirmDuplicateModal}
        title="Duplicate Project"
        message={`Are you sure you want to duplicate this project?`}
        onConfirm={() => duplicateProject()}
        onCancel={() => setShowConfirmDuplicateModal(false)}
      />
      <ScopeStackModal
        modalTitle="Add Teammates"
        modalBody={
          <div>
            <Select2V2
              label={"Invite Teammates"}
              options={teammateOptions}
              isDisabled={false}
              defaultValue={undefined}
              onChange={(e) => {
                setTeammates(e);
              }}
              isMulti={true}
            />
          </div>
        }
        button1Text="Cancel"
        handleButton1Click={() => setShowAddTeammatesModal(false)}
        button2Text={"Add"}
        handleButton2Click={() => {
          addTeammates();
        }}
        button2Disabled={teammates?.length === 0}
        show={showAddTeammatesModal}
        handleClose={() => setShowAddTeammatesModal(false)}
      />
      <GenerateDocument
        orientation="horizontal"
        show={showGenerateDocumentModal}
        setShow={setShowGenerateDocumentModal}
        account_slug={account_slug}
        project_id={project_id}
        saveToCloudStorageFlag={save_to_cloud_storage_flag}
        isStationary={false}
        authorizationCode={authorizationCode}
      />
    </div>
  );
}

export default SowHeader;
