import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { LDProvider } from "launchdarkly-react-client-sdk";
import store from "./store";
import AccountMspa from "./pages/Account/AccountMspa";
import ProjectsMspa from "./pages/Projects/ProjectsMspa";
import TeamsMspa from "./pages/Admin/Teams/TeamsMspa";
import LaunchDarklyProvider from "./common/launchDarkly/LaunchDarklyProvider";
import SurveyMspa from "./pages/Surveys/SurveyMspa";
import { Dashboard } from "./pages/DashboardV2";
import { ThemeProvider } from "@emotion/react";
import theme from "./themes/theme";
import AuthorizationProvider from "./common/providers/AuthorizationProvider";
import Products from "./pages/Products";
import ConnectedApps from "./pages/Admin/ConnectedApps";
import { TravelAndExpenseV2Mspa } from "./pages/TravelAndExpenseV2";
import { GovernanceV2Mspa } from "./pages/GovernanceV2";
import { VendorQuotesV2Mspa } from "./pages/VendorQuotesV2";
import { ProfessionalServicesMspa } from "./pages/Admin/ProfessionalServices";
import { DocumentTemplatesMspa } from "./pages/Admin/DocumentTemplates";
import { ProjectVersionsV2Mspa } from "./pages/ProjectVersionsV2";

const App = (props) => {
  const { account_slug, project_id } = props;

  if (!account_slug || account_slug === "" || account_slug === undefined) {
    throw new Error("!! Extreme danger !! account_slug is required");
  }

  return (
    <Provider store={store}>
      <AuthorizationProvider account_slug={account_slug}>
        <ThemeProvider theme={theme}>
          <LDProvider
            clientSideID={
              process.env.REACT_APP_LAUNCHDARKLY_CLIENT_ID || "dummyclient"
            }
          >
            <LaunchDarklyProvider accountSlug={account_slug}>
              <Router>
                <Routes>
                  <Route path="/home" element={<Dashboard {...props} />} />
                  <Route path="/users/*" element={<AccountMspa {...props} />} />
                  <Route
                    path="/projects/:id/project_materials/*"
                    element={<Products {...props} />}
                  />
                  <Route
                    path="/projects/*"
                    element={<ProjectsMspa {...props} />}
                  />
                  <Route
                    path="/surveys/*"
                    element={<SurveyMspa {...props} />}
                  />
                  <Route
                    path="/admin/account_connections/*"
                    element={<ConnectedApps {...props} />}
                  />
                  <Route
                    path="/admin/teams/*"
                    element={<TeamsMspa {...props} />}
                  />
                  <Route
                    path="/projects/:id/project_expenses/*"
                    element={<TravelAndExpenseV2Mspa {...props} />}
                  />
                  <Route
                    path="/projects/:id/project_governances/*"
                    element={<GovernanceV2Mspa {...props} />}
                  />
                  <Route
                    path="/projects/:id/quotes/*"
                    element={<VendorQuotesV2Mspa {...props} />}
                  />
                  <Route
                    path="/admin/tasks/*"
                    element={
                      <ProfessionalServicesMspa
                        {...props}
                        serviceType="professional_services"
                      />
                    }
                  />
                  <Route
                    path="/admin/managed_services/*"
                    element={
                      <ProfessionalServicesMspa
                        {...props}
                        serviceType="managed_services"
                      />
                    }
                  />
                  <Route
                    path="/admin/document_templates/*"
                    element={<DocumentTemplatesMspa {...props} />}
                  />
                  <Route
                    path="/projects/:id/project_versions/*"
                    element={<ProjectVersionsV2Mspa {...props} />}
                  />
                </Routes>
              </Router>
            </LaunchDarklyProvider>
          </LDProvider>
        </ThemeProvider>
      </AuthorizationProvider>
    </Provider>
  );
};

export default App;
