import FormFieldLabel from "@components/Forms/FormFieldLabel/FormFieldLabel";
import API from "../../utils/API/API";
import { Select2V2 } from "@components/FormsV2";
import ScopeStackModal from "@components/ScopeStackModal/ScopeStackModal";
import useOauth from "@utils/customHooks/useOauth";
import React, { useEffect, useState } from "react";
import { Button, Form, FormCheck, Modal } from "react-bootstrap";
import { handleAlert, validateEmails } from "@utils/helperFunctions";
import "./style/style.css";
import SmallSpinner from "@components/SmallSpinner/SmallSpinner";
import ToastAlert from "@components/Alerts/ToastAlert/ToastAlert";
import loading from "./images/SS-icon-checkmark.gif";
import successImg from "./images/SS-icon-checkmark.png";
import StationaryModal from "@components/StationaryModal/StationaryModal";

function GenerateDocument({
  orientation,
  show,
  setShow,
  account_slug,
  project_id,
  saveToCloudStorageFlag,
  isStationary,
  authorizationCode,
}) {
  const apiHost = process.env.REACT_APP_SCOPESTACK_API_HOST;
  const [generateDocumentOptions, setGenerateDocumentOptions] = useState([
    { name: "Download" },
    { name: "Email" },
    { name: "Save To Cloud Storage" },
  ]);
  const [documentTypes, setDocumentTypes] = useState<
    ProjectDownLoadType[] | []
  >([]);
  const [selectedDocumentType, setSelectedDocumentType] = useState({
    label: "",
    value: 0,
  });
  const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState("");
  const [showEmailField, setShowEmailField] = useState(false);
  const [emailList, setEmailList] = useState("");
  const [emailsAreInvalid, setEmailsAreInvalid] = useState(false);
  const [emailListChanged, setEmailListChanged] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [documentId, setDocumentId] = useState(null);
  const [documentURL, setDocumentURL] = useState(null);
  const [generateDocumentIsSubmitted, setGenerateDocumentIsSubmitted] =
    useState(false);
  const [errorMessages, setErrorMessages] = useState("");
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [showSuccessfulGenerationModal, setshowSuccessfulGenerationModal] =
    useState(false);

  useEffect(() => {
    if (authorizationCode !== "") {
      //Check for docusign
      API.Get(`${apiHost}/v1/accounts/${account_slug}`, authorizationCode).then(
        (res) => {
          const documentGenerateOptions = saveToCloudStorageFlag
            ? generateDocumentOptions
            : generateDocumentOptions.filter(
                (option) => option.name !== "Save To Cloud Storage"
              );
          if (res.data.data.attributes["esignature?"]) {
            if (
              !generateDocumentOptions.find(
                (option) => option.name === "Electronic Signature"
              )
            ) {
              setGenerateDocumentOptions([
                { name: "Electronic Signature" },
                ...documentGenerateOptions,
              ]);
              setSelectedDeliveryMethod("docusign");
            }
          } else {
            setGenerateDocumentOptions(documentGenerateOptions);
          }
          setIsLoading(false);
        }
      );

      //GET ProjectDownloadTypes
      API.Get(
        `${apiHost}/${account_slug}/v1/projects/${project_id}/project-download-types`,
        authorizationCode
      ).then((response) => {
        setDocumentTypes(response.data.data);
        setSelectedDocumentType({
          value: response.data.data[0].attributes["template-id"],
          label: response.data.data[0].attributes.name,
        });
        setIsLoading(false);
      });
    }
  }, [show]);

  useEffect(() => {
    const checkForDocURL = setInterval(() => {
      if (documentId !== null && selectedDeliveryMethod !== "docusign") {
        API.Get(
          `${apiHost}/${account_slug}/v1/project-documents/${documentId}`,
          authorizationCode
        ).then((res) => {
          if (
            res.data.data.attributes["document-url"] !== null &&
            res.data.data.attributes.status === "finished"
          ) {
            setDocumentURL(res.data.data.attributes["document-url"]);
            clearInterval(checkForDocURL);
          }
        });
      } else {
        clearInterval(checkForDocURL);
      }
    }, 1000);
  }, [documentId]);

  const renderProjectDownLoadOptions = () => {
    let options = new Set();
    let documentGroups = new Set();
    documentTypes.forEach((documentType) => {
      documentGroups.add(documentType?.["attributes"]?.["group"]);
    });

    Array.from(documentGroups).forEach((docGroup) => {
      options.add({
        label: docGroup,
        options: documentTypes
          .filter((docType) => docType?.["attributes"]?.["group"] === docGroup)
          .map((dt) => {
            return {
              label: dt?.["attributes"]?.["name"],
              value: dt?.["attributes"]?.["template-id"],
            };
          }),
      });
    });
    return Array.from(options);
  };

  const closeModal = () => {
    setShow(false);
    setShowEmailField(false);
    setEmailList("");
    setEmailListChanged(false);
  };

  const closeSuccessModal = () => {
    setDocumentId(null);
    setDocumentURL(null);
    setGenerateDocumentIsSubmitted(false);
    setshowSuccessfulGenerationModal(false);
  };

  const generateDocument = (e) => {
    e.preventDefault();

    setShow(false);
    if (selectedDeliveryMethod !== "docusign") {
      setshowSuccessfulGenerationModal(true);
    }

    setGenerateDocumentIsSubmitted(true);

    let documentData = {
      data: {
        type: "project-documents",
        attributes: {
          "document-type":
            documentTypes.length > 0
              ? documentTypes?.find(
                  (documentType) =>
                    documentType?.attributes?.["template-id"] ===
                    selectedDocumentType?.value
                )?.attributes?.["document-type"]
              : "",
          "delivery-type": selectedDeliveryMethod,
          "template-id": selectedDocumentType.value,
        },
        relationships: {
          project: { data: { id: project_id, type: "projects" } },
        },
      },
    };

    if (selectedDeliveryMethod == "email") {
      if (!validateEmails(emailList) || emailList === "") {
        return;
      }
      documentData.data.attributes["email-list"] = emailList;
    }

    API.Post(
      `${apiHost}/${account_slug}/v1/project-documents`,
      documentData,
      authorizationCode
    ).then((res) => {
      if (res.data.data.attributes.status === "failed") {
        let errorMsgs = res.data.data.attributes["error-text"];
        setErrorMessages(errorMsgs.split("\n").map((msg) => msg + "."));
        setShowFailAlert(true);
      } else {
        if (selectedDeliveryMethod === "docusign") {
          setSuccessMessage("Submitted to Docusign.");
          setShowSuccessAlert(true);
        } else {
          setDocumentId(res.data.data.id);
        }
      }
    });
  };

  const getGenerateForm = () => {
    return (
      <Form
        style={{
          display: "flex",
          flexDirection: orientation === "horizontal" ? "row" : "column",
        }}
      >
        <div>
          <div className="text20">1. Generation Method</div>
          <p>What ype of document would you like to generate?</p>
          <Select2V2
            label={"*Document Type"}
            options={renderProjectDownLoadOptions()}
            isDisabled={false}
            onChange={(e) => setSelectedDocumentType(e)}
            value={selectedDocumentType}
          />
          <p>How would you like to generate this document type?</p>
          {generateDocumentOptions.map((item, index) => (
            <FormCheck
              defaultChecked={index == 0 ? true : false}
              required={true}
              id={
                item.name == "Email"
                  ? "email"
                  : item.name == "Download"
                  ? "download"
                  : "docusign"
              }
              className="deliveryCheckbox"
              key={index}
              label={item.name}
              name="group1"
              type="radio"
              onChange={(e) => {
                setSelectedDeliveryMethod(e?.target?.id);
                if (e.target.id == "email") {
                  setShowEmailField(true);
                } else {
                  setShowEmailField(false);
                }
              }}
            />
          ))}
        </div>
        {showEmailField && (
          <div
            className={
              orientation === "horizontal"
                ? "emailSectionHorizontal"
                : "emailSectionVertical"
            }
          >
            <div className="text20">2. Send by Email</div>
            <p>Where would you like the generated document sent?</p>
            <Form.Group className="formGroup">
              <FormFieldLabel
                className={""}
                label="Email List (comma separated list of emails) *"
              />
              <Form.Control
                required={true}
                value={emailList}
                onChange={(e) => {
                  setEmailListChanged(true);
                  setEmailList(e.target.value);
                }}
                isInvalid={emailsAreInvalid && emailListChanged}
                onBlur={() =>
                  setEmailsAreInvalid(
                    (!validateEmails(emailList) || emailList === "") &&
                      selectedDeliveryMethod === "email"
                  )
                }
              />
              {emailsAreInvalid && (
                <Form.Control.Feedback type="invalid">
                  Please enter a valid list of emails
                </Form.Control.Feedback>
              )}
            </Form.Group>
          </div>
        )}
        {isStationary && (
          <Button
            style={
              selectedDeliveryMethod !== "email" ? { marginTop: "20px" } : {}
            }
            onClick={(e) => generateDocument(e)}
            className="seafoamBgButton"
            disabled={
              (!validateEmails(emailList) || emailList === "") && showEmailField
            }
          >
            Generate
          </Button>
        )}
      </Form>
    );
  };

  return (
    <div>
      {handleAlert(
        showFailAlert,
        errorMessages,
        setShowFailAlert,
        "danger",
        ToastAlert
      )}
      {handleAlert(
        showSuccessAlert,
        successMessage,
        setShowSuccessAlert,
        "success",
        ToastAlert
      )}
      {isStationary ? (
        <StationaryModal
          header={"Generate Document"}
          body={isLoading ? <SmallSpinner /> : getGenerateForm()}
        />
      ) : (
        <ScopeStackModal
          className={
            orientation === "horizontal" && showEmailField ? "wide " : ""
          }
          modalTitle={"Generate Document"}
          modalBody={isLoading ? <SmallSpinner /> : getGenerateForm()}
          button1Text="Cancel"
          handleButton1Click={() => closeModal()}
          button2Text={"Generate"}
          handleButton2Click={(e) => generateDocument(e)}
          button2Disabled={
            (!validateEmails(emailList) || emailList === "") && showEmailField
          }
          show={show}
          handleClose={() => closeModal()}
        />
      )}
      <Modal
        centered
        show={showSuccessfulGenerationModal}
        onHide={closeSuccessModal}
      >
        <Modal.Body>
          {documentURL && selectedDeliveryMethod === "email" ? (
            <div>
              <img src={successImg} style={{ margin: "0px 115px 0px 135px" }} />
              <h1 style={{ textAlign: "center" }}>Success!</h1>
            </div>
          ) : (
            <div>
              <img src={loading} style={{ margin: "0 105px" }} />
              <p style={{ textAlign: "center" }}>
                The document is being generated. It will be{" "}
                {selectedDeliveryMethod === "download"
                  ? "available to download"
                  : "sent via email"}{" "}
                as soon as the file is ready. Please do not close browser
                window.
              </p>
            </div>
          )}
        </Modal.Body>
        {documentURL && (
          <Modal.Footer>
            <>
              <Button variant="secondary" onClick={closeSuccessModal}>
                Close
              </Button>
              {selectedDeliveryMethod === "download" && (
                <Button variant="primary" href={documentURL} target="_blank">
                  Download
                </Button>
              )}
            </>
          </Modal.Footer>
        )}
      </Modal>
    </div>
  );
}

export default GenerateDocument;
